<!-- src/components/Login.vue -->
<!-- Login.vue -->
<template>
  <div class="login-container">
    <div class="login-card">
      <h2 class="login-title">Login</h2>
      <form @submit.prevent="login" class="login-form">
        <div v-if="loginError" class="login-error">
          Please adjust the following:
          <br>{{ loginError }}
        </div>
        <div class="form-group">
          <label for="email" class="form-label">Email</label>
          <input id="email" v-model="email" type="email" required class="form-input" />
        </div>
        <div class="form-group">
          <label for="password" class="form-label">Password</label>
          <input id="password" v-model="password" type="password" required class="form-input" />
        </div>
        <button type="submit" class="login-button">Sign In</button>
      </form>
    </div>
  </div>
</template>
  
  <script>
import { mapActions } from 'vuex';
import { getAuth,  signInWithEmailAndPassword } from 'firebase/auth';

export default {
  data() {
    return {
      email: '',
      password: '',
      loginError: '',
    };
  },
 
  methods: {
    ...mapActions(['loginUser']),
    async login() {
      const auth = getAuth();
      signInWithEmailAndPassword(auth, this.email, this.password)
        .then((userCredential) => {
          this.$router.push('/admin');
          this.loginUser({ email: this.email, password: this.password });
          const user = userCredential.user;
          console.log(user);
          
        })
        .catch((error) => {
          this.loginError = error.message;
          console.log(error.code);
        });
    },
  },
};
</script>
<style>
.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f1f1f1;
}

.login-card {
  background: #fff;
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  max-width: 400px;
  width: 100%;
}

.login-title {
  font-size: 1.8rem;
  color: #333;
  margin-bottom: 1.5rem;
  text-align: center;
}

.login-form {
  display: flex;
  flex-direction: column;
}

.form-group {
  margin-bottom: 1rem;
}

.form-label {
  display: block;
  margin-bottom: 0.5rem;
  font-weight: bold;
  color: #333;
}

.form-input {
  width: 100%;
  padding: 0.8rem;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 1rem;
}

.login-button {
  background-color: #333;
  color: #fff;
  border: none;
  padding: 1rem;
  font-size: 1rem;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.login-button:hover {
  background-color: #555;
}

.login-error {
  padding: 1rem;
  margin-bottom: 1rem;
  background-color: #fee2e2;
  border: 1px solid #fecaca;
  color: #b91c1c;
  border-radius: 4px;
  text-align: center;
}
</style>
  