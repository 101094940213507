<!-- src/components/PageEditor.vue -->
<!-- PageEditor.vue -->
<template>
  <div>
    <div class="header-buttons">
      <div class="back-button">
        <router-link :to="`/admin/pages`"
          ><FeatherIcon name="arrow-left"
        /></router-link>
      </div>
      <div class="header-title">{{ page.title }}</div>
      <div class="header-right">
        <router-link :to="`/${page.pageSlug}`" class="link-con">
          <FeatherIcon class="eye-icon" name="eye" />
          <div class="header-view">View</div></router-link
        >
      </div>
    </div>
    <div class="editor-container">
      <div class="page-details">
        <label for="title"><h3>Title</h3></label>
        <input id="title" type="text" v-model="page.title" />

        <div>
          <label for="content"><h3>Content</h3></label>
          <textarea
            name="content"
            class="elastic-textarea"
            v-autosize
            v-model="page.content"
          ></textarea>
        </div>
        <div></div>
      </div>

      <div class="page-settings">
        <div class="page-actions-container">
          <label><h2>Visibility</h2></label>
          <div class="visibility-section">
            <div class="visibility-options">
              <label>
                <input
                  type="radio"
                  v-model="page.visibility"
                  value="true"
                  name="vis"
                  :checked="page.visibility === true"
                />
                Visible (As of {{ this.page.updatedDate }})
              </label>
              <label>
                <input
                  type="radio"
                  v-model="page.visibility"
                  value="false"
                  name="vis"
                  :checked="page.visibility === false"
                />
                Hidden
              </label>
            </div>
          </div>
        </div>

        <div class="page-actions-container col-top">
          <label><h2>Nav Bar Visibility</h2></label>
          <div class="visibility-section">
            <div class="visibility-options">
              <label>
                <input
                  type="radio"
                  v-model="page.navVisibility"
                  value="true"
                  name="vist"
                  :checked="page.navVisibility === true"
                />Visible
              </label>
              <label>
                <input
                  type="radio"
                  v-model="page.navVisibility"
                  value="false"
                  name="vist"
                  :checked="page.navVisibility === false"
                />
                Hidden
              </label>
            </div>
          </div>
        </div>

        <div class="page-actions-container col-top">
          <div class="template-section">
            <label><h2>Template</h2></label>
            <select v-model="page.template">
              <option value="default">page</option>
              <option value="alternate">page.contact.alternate</option>
              <option value="contact">page.contact</option>
              <option value="noasides">page.noasides</option>
            </select>
          </div>
        </div>

        <div class="page-actions-container col-top">
          <div class="template-section">
            <label><h2>Page Slug</h2></label>

            yoursite.com/{{ page.pageSlug
            }}<input
              id="slug"
              type="text"
              v-model="page.pageSlug"
              value="page.pageSlug"
            />
          </div>
        </div>

        <button class="save-button" @click="updatePage" type="submit">
          Save
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import { autosize } from "@/directives/autosize";

export default {
  directives: {
    autosize,
  },
  components: {},
  watch: {
    currentPage(newVal) {
      this.page = Object.assign({}, newVal);
    },
  },
  data() {
    return {
      navConst: "",
      page: {
        id: "",
        title: "",
        content: "",
        pageSlug: "",
        visibility: false,
        navVisibility: false,
        updatedDate: "",
      },
    };
  },
  props: {
    pageId: String,
  },
  computed: {
    currentPage() {
      return this.$store.getters.currentPage;
    },
    updated() {
      const date = new Date();
      const options = { year: "numeric", month: "long", day: "numeric" };
      return (
        date.toLocaleDateString("en-US", options) + " " + this.formatAMPM(date)
      );
    },
  },
  methods: {
    updatePage() {
      const date = new Date();
      const options = { year: "numeric", month: "long", day: "numeric" };
      this.page.updatedDate =
        date.toLocaleDateString("en-US", options) + " " + this.formatAMPM(date);
      if (
        this.page.title &&
        this.page.content &&
        this.page.pageSlug &&
        this.page.visibility
      ) {
          

        if(this.page.navVisibility == "true"){
          this.navConst = true;
        } else {
          this.navConst = false;
        }
        this.$store.dispatch("updatePage", {
          id: this.page.id,
          title: this.page.title,
          pageSlug: this.page.pageSlug,
          content: this.page.content,
          visibility: this.page.visibility,
          navVisibility: this.navConst,
          updatedDate: this.page.updatedDate,
        });
      } else {
        alert("Please fill in all fields");
      }
    },
    formatAMPM(date) {
      let hours = date.getHours();
      let minutes = date.getMinutes();
      const ampm = hours >= 12 ? "PM" : "AM";
      hours = hours % 12;
      hours = hours || 12; // the hour '0' should be '12'
      minutes = minutes < 10 ? "0" + minutes : minutes;
      const strTime = hours + ":" + minutes + " " + ampm;
      return strTime;
    },
  },
  mounted() {
    this.$store.dispatch("getPageById", this.$route.params.id);
    this.page = Object.assign({}, this.currentPage);
    this.$store.dispatch("loadPages");
  },
};
</script>

<style scoped>
.content {
  width: 1000px !important;
}
.admin-container {
  width: 500px;
}
.editor-container {
  display: flex;
  box-sizing: border-box;
  padding: 0px;
  background: #f5f5f5;
}

.page-details {
  flex-grow: 2;
  margin-right: 40px;
  margin-left: 60px;
  margin-bottom: 20px;
  background: white;
  padding: 50px;
  border-radius: 10px;
}

.page-details-lower {
  flex-grow: 2;
  margin-right: 40px;
  margin-left: 60px;
  margin-top: 40px;
  background: white;
  padding: 50px;
  border-radius: 10px;
}

.page-settings {
  flex-basis: 500px;
}

.editor-container label {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.editor-container h2 {
  color: #666666;
}

.page-actions-container label {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #666666;
}

.page-actions-container {
  padding: 40px;
  background-color: #ffffff;
  border-radius: 10px;
}

.page-actions button {
  margin-left: 10px;
  padding: 5px 15px;
  cursor: pointer;
}

.view-button {
  color: white;
  border: none;
  border-radius: 4px;
}

.save-button {
  background: #08893c;
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 14px;
  padding: 15px 30px;
  margin: 40px 350px 0px 0;
}

label {
  display: block;
  margin: 10px 0;
  color: #637381;
}

input[type="text"],
textarea {
  width: 100%;
  padding: 8px;
  margin-bottom: 20px;
  border: 1px solid #d3d3d3;
  border-radius: 4px;
}

select {
  width: 100%;
  padding: 8px;
  border: 1px solid #d3d3d3;
  border-radius: 4px;
  background: white;
}

.visibility-section h2,
.template-section h2 {
  color: #212b36;
  margin-bottom: 10px;
}

.visibility-options label {
  display: block;
  margin: 10px 0;
}

textarea {
  min-height: 100px;
  outline: none;
}
.header-buttons {
  display: flex;
  justify-content: space-between;
  margin: 10px 20px;
}
.header-title {
  margin: 10px 30px;
  font-weight: bold;
  font-size: 20px;
}

.header-right {
  margin-left: auto;
}

.back-button {
  padding: 6px;
  border: solid 1px #888888;
  border-radius: 5px;
  max-width: 25px;
  max-height: 25px;
}

.link-con {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.eye-icon {
  margin: 5px;
  font-weight: bold;
  flex: 1;
}

.header-view {
  flex: 1;
}

.col-top {
  margin-top: 40px;
}
</style>
