<!-- src/components/NewPage.vue -->
<!-- NewPage.vue -->
<!-- <template>
  <div>
    <h1>Add New Page</h1>
    <form @submit.prevent="submitPage">
      <input type="text" v-model="newPage.title" placeholder="Title" />
      <input type="text" v-model="newPage.pageSlug" placeholder="Page Slug" />
      <br />
      <textarea v-model="newPage.content"></textarea>
      <br />
      <button type="submit">Add Page</button>
    </form>
  </div>
</template> -->
<template>
  <div>
    <div class="header-buttons">
      <div class="back-button">
        <router-link :to="`/admin/pages`"
          ><FeatherIcon name="arrow-left"
        /></router-link>
      </div>
      <div class="header-title">{{ newPage.title }}</div>
      <div class="header-right">

      </div>
    </div>
    <div class="editor-container">
      <div class="page-details">
        <label for="title"><h3>Title</h3></label>
        <input id="title" type="text" v-model="newPage.title" />

        <div>
          <label for="content"><h3>Content</h3></label>
          <textarea
            name="content"
            class="elastic-textarea"
            v-autosize
            v-model="newPage.content"
          ></textarea>
        </div>
        <div></div>
      </div>


      
      <div class="page-settings">
        <div class="page-actions-container">
          <label><h2>Visibility</h2></label>
          <div class="visibility-section">
            <div class="visibility-options">
              <label>
                <input type="radio" v-model="newPage.visibility" value="true" name="vis" checked/>
                Visible
              </label>
              <label>
                <input type="radio" v-model="newPage.visibility" value="false" name="vis"/>
                Hidden
              </label>
            </div>
          </div>

        </div>

        <div class="page-actions-container col-top">
          <div class="template-section">
            <label><h2>Template</h2></label>
            <select v-model="newPage.template">
              <option value="default">page</option>
              <option value="alternate">newPage.contact.alternate</option>
              <option value="contact">newPage.contact</option>
              <option value="noasides">newPage.noasides</option>
            </select>
          </div>
        </div>

        <div class="page-actions-container col-top">
          <div class="template-section">
            <label><h2>Page Slug</h2></label>

          <input
            id="slug"
            type="text"
            v-model="newPage.pageSlug"
            value="page.pageSlug"
          />

          </div>
        </div>

        <button class="save-button" @click="submitPage" type="submit">
              Save
            </button>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions } from "vuex";
import { autosize } from "@/directives/autosize";

export default {
  directives: {
    autosize,
  },
  components: {
    
  },
  data() {
    return {
      newPage: {
        title: "",
        content: "",
        pageSlug: "",
        visibility: false,
        updatedDate: "",
      },
    };
  },
  computed: {
    updated() {
      const date = new Date();
      const options = { year: 'numeric', month: 'long', day: 'numeric' };
      return date.toLocaleDateString('en-US', options) + ' ' + this.formatAMPM(date);
    },
  },
  methods: {
    ...mapActions(["addPage"]),
    submitPage() {
      const date = new Date();
      const options = { year: 'numeric', month: 'long', day: 'numeric' };
      this.newPage.updatedDate = date.toLocaleDateString('en-US', options) + ' ' + this.formatAMPM(date);
      if (this.newPage.title && this.newPage.content && this.newPage.pageSlug) {
        const newPage = {
          title: this.newPage.title,
          content: this.newPage.content,
          pageSlug: this.newPage.pageSlug,
          visibility: this.newPage.visibility,
          updatedDate: this.newPage.updatedDate,
        };

        this.addPage(newPage);
        this.newPage.title = "";
        this.newPage.content = "";
        this.newPage.pageSlug = "";
        this.$router.push({ path: '/admin/pages' })
      } else {
        alert("Please fill in all fields");
      }
    },
  formatAMPM(date) {
      let hours = date.getHours();
      let minutes = date.getMinutes();
      const ampm = hours >= 12 ? 'PM' : 'AM';
      hours = hours % 12;
      hours = hours || 12; // the hour '0' should be '12'
      minutes = minutes < 10 ? '0' + minutes : minutes;
      const strTime = hours + ':' + minutes + ' ' + ampm;
      return strTime;
    },
  },
  // beforeRouteEnter(to, from, next) {
  //   next(vm => {
  //     vm.$store.dispatch('fetchPages');
  //   });
  // },
  mounted() {
    this.$store.dispatch("loadPages");
  },
}
</script>
<style scoped>
.content {
  width: 1000px !important;
}
.admin-container {
  width: 500px;
}
.editor-container {
  display: flex;
  box-sizing: border-box;
  padding: 0px;
  background: #f5f5f5;
}

.page-details {
  flex-grow: 2;
  margin-right: 40px;
  margin-left: 60px;
  margin-bottom: 20px;
  background: white;
  padding: 50px;
  border-radius: 10px;
}

.page-details-lower {
  flex-grow: 2;
  margin-right: 40px;
  margin-left: 60px;
  margin-top: 40px;
  background: white;
  padding: 50px;
  border-radius: 10px;
}

.page-settings {
  flex-basis: 500px;
}

.editor-container label {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.editor-container h2 {
  color: #666666;
}

.page-actions-container label {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #666666;
}

.page-actions-container {
  padding: 40px;
  background-color: #ffffff;
  border-radius: 10px;
}

.page-actions button {
  margin-left: 10px;
  padding: 5px 15px;
  cursor: pointer;
}

.view-button {
  color: white;
  border: none;
  border-radius: 4px;
}

.save-button {
  padding: 10px 20px;
  background: linear-gradient(135deg, #842bd7 0%, #c643fc 100%) !important;
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
  transition: all 0.3s ease;
  margin: 40px 350px 0px 0;
}

label {
  display: block;
  margin: 10px 0;
  color: #637381;
}

input[type="text"],
textarea {
  width: 100%;
  padding: 8px;
  margin-bottom: 20px;
  border: 1px solid #d3d3d3;
  border-radius: 4px;
}

select {
  width: 100%;
  padding: 8px;
  border: 1px solid #d3d3d3;
  border-radius: 4px;
  background: white;
}

.visibility-section h2,
.template-section h2 {
  color: #212b36;
  margin-bottom: 10px;
}

.visibility-options label {
  display: block;
  margin: 10px 0;
}

textarea {
  min-height: 100px;
  outline: none;
}
.header-buttons {
  display: flex;
  justify-content: space-between;
  margin: 10px 20px;
}
.header-title {
  margin: 10px 30px;
  font-weight: bold;
  font-size: 20px;
}

.header-right {
  margin-left: auto;
}

.back-button {
  padding: 6px;
  border: solid 1px #888888;
  border-radius: 5px;
  max-width: 25px;
  max-height: 25px;
}

.link-con {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.eye-icon {
  margin: 5px;
  font-weight: bold;
  flex: 1;
}

.header-view {
  flex: 1;
}

.col-top{
  margin-top: 40px;
}
</style>