export const autosize = {
    mounted(el) {
      el.style.overflowY = 'hidden';
      el.style.resize = 'none';
      setHeight(el);
    },
    updated(el) {
      setHeight(el);
    }
  };
  
  function setHeight(el) {
    // Reset height to 'auto' to get the correct scrollHeight
    el.style.height = 'auto';
    el.style.height = el.scrollHeight + 'px';
  }
  