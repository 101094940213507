//main.js
import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import "./firebaseConfig";
import "./assets/styles/theme.css";
import HeaderComponent from "./components/HeaderComponent.vue";
import feather from "feather-icons";
import FeatherIcon from "./components/FeatherIcon.vue";



const auth = getAuth();
let app;
onAuthStateChanged(auth, (user) => {
  if (!app) {
    app = createApp(App);
    app.config.globalProperties.$feather = feather;
    app.component("FeatherIcon", FeatherIcon);
    app
      .component("header-component", HeaderComponent)
      .use(store)
      .use(router)
      .mount("#app");
  }

  if (user) {
    // User is signed in
    store.commit("setUser", user); // Assuming you have a setUser mutation
    if (router.currentRoute.value.path === "/login") {
      router.push("/admin"); // Redirect to admin if on login page
    }
    store.commit("setUser", user);
    store.commit("setAuthentication", true);
  } else {
    // User is signed out
    store.commit("setUser", null);
    // if (router.currentRoute.value.path !== "/login") {
    //   router.push("/login"); // Redirect to login if not already there
    // }
    store.commit("setUser", null);
    store.commit("setAuthentication", false);
  }
});
