<template>
  <div>
    <div v-if="pageData">
      <div v-html="pageData.content"></div>
    </div>
   
    <div v-else-if="pageData== ''">
      <h1>404 - Page Not Found</h1>
      <p>The page you are looking for doesn't exist or has been moved.</p>
      <router-link to="/">Go Back Home</router-link>
    </div>
    <div v-else>Loading...</div>
  </div>
</template>

<script>
import { ref } from "vue";
import { useRoute } from "vue-router";
import { db } from "@/firebaseConfig";
import { collection, query, where, getDocs } from "firebase/firestore";
import { watchEffect } from "vue";

export default {
  setup() {
    const route = useRoute();
    const pageData = ref(null);
    watchEffect(async () => {
      const pageSlug = route.params.pageSlug;
      const pagesCollection = collection(db, "pages");
      const q = query(
        pagesCollection,
        where("pageSlug", "==", pageSlug, "visibility", "==", true)
      );
      const querySnapshot = await getDocs(q);
      if (!querySnapshot.empty) {
        pageData.value = querySnapshot.docs[0].data();
      
      } else {
        pageData.value = "";
        console.log("Page not found");
      }
    });

    return { pageData };
  },
};
</script>
