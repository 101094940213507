<!-- src/components/HomePageEditor.vue -->
<!-- HomePageEditor.vue -->
<template>
  <div>
    <div class="header-buttons">
      <div class="back-button">
        <router-link :to="`/admin`"
          ><FeatherIcon name="arrow-left"
        /></router-link>
      </div>
      <div class="header-title">Preferences</div>
      <div class="header-right">
        <router-link :to="`/`" class="link-con">
          <FeatherIcon class="eye-icon" name="eye" />
          <div class="header-view">View</div></router-link
        >
      </div>
    </div>
    <!-- Editor section 1-->
    <div class="editor-container">
      <!-- Left section -->
      <div class="left-description">
        <p>Homepage Section</p>
        The brand name is used for the logo alt. Logo is th url of your logo
        image. Content is what displays on the homepage.
      </div>

      <div class="page-details">
        <div class="page-details-bg">
          <label for="title"><h3>Brand Name</h3></label>
          <input
            id="title"
            type="text"
            v-model="homepageData.headerText"
            :class="{ 'error-input': errors.headerText }"
          />

          <label for="logo"><h3>Logo</h3></label>
          <input id="logo" type="text" v-model="homepageData.logoUrl" />

          <div>
            <label for="content"><h3>Content</h3></label>
            <textarea
              name="content"
              class="elastic-textarea"
              v-autosize
              v-model="homepageData.content"
              :class="{ 'error-input': errors.content }"
            ></textarea>
          </div>
        </div>
      </div>
    </div>

    <!-- Editor section 2-->
    <div class="editor-container">
      <!-- Left section -->
      <div class="left-description">
        <p>Title and meta description</p>
        The title and meta description help define how your website shows up
        search engines.
      </div>

      <div class="page-details">
        <div class="page-details-bg">
          <label for="title"><h3>Homepage Title</h3></label>
          <input
            id="title"
            type="text"
            v-model="homepageData.homepageTitle"
            :class="{ 'error-input': errors.homepageTitle }"
          />

          <div>
            <label for="content"><h3>Homepage meta description</h3></label>
            <textarea
              name="content"
              class="elastic-textarea"
              v-autosize
              v-model="homepageData.homepageMeta"
              :class="{ 'error-input': errors.homepageMeta }"
            ></textarea>
          </div>
        </div>
      </div>
    </div>
    <!-- Editor section 3-->
    <div class="editor-container">
      <!-- Left section -->
      <div class="left-description">
        <p>Facebook Pixel</p>
        Facebook pixel tracks funnel user behavior on your online website.
      </div>

      <div class="page-details">
        <div class="page-details-bg">
          <div>
            <label for="content"><h3>Facebook Pixel Setup</h3></label>
            <textarea
              name="content"
              class="elastic-textarea"
              v-autosize
              v-model="homepageData.facebookPixel"
            ></textarea>
          </div>
        </div>
      </div>
    </div>
    <!-- Editor section 4-->
    <div class="editor-container">
      <!-- Left section -->
      <div class="left-description">
        <p>Google Analytics</p>
        Google Analytics enables you to track the vistorsto your website, and
        generate reports that will help you with your marketing.
      </div>

      <div class="page-details">
        <div class="page-details-bg">
          <div>
            <label for="content"><h3>Google Analytics Account</h3></label>
            <textarea
              name="content"
              class="elastic-textarea"
              v-autosize
              v-model="homepageData.googleAnalytics"
            ></textarea>
          </div>
        </div>
      </div>
    </div>

    <!-- Editor section 5-->
    <div class="editor-container">
      <!-- Left section -->
      <div class="left-description">
        <p>Email Template</p>
        Customize the html email trmplate. And the this is the plaintext section of the email body.
      </div>

      <div class="page-details">
        <div class="page-details-bg">
          <div>
            <label for="subject"><h3>Welcome Email Subject</h3></label>
            <input
            name="subject"
            id="title"
            type="text"
            v-model="homepageData.emailSubject"
          />
          </div>
          <div>
            <label for="content"><h3>Welcome Email Plaintext</h3></label>
            <textarea
              name="content"
              class="elastic-textarea"
              v-autosize
              v-model="homepageData.emailText"
            ></textarea>
          </div>
          <div>
            <label for="content"><h3>Welcome Email Template</h3></label>
            <textarea
              name="content"
              class="elastic-textarea"
              v-autosize
              v-model="homepageData.emailTemplate"
            ></textarea> 
          </div>
        </div>
      </div>
    </div>
    <!-- Error message section-->
    <button class="save-button" @click="saveHomepage" type="submit">
      Save
    </button>
    <i class="error-message" v-if="showError">
      {{ errorMessage }}
    </i>
    <i class="sucess-message" v-if="showSucess">
      {{ sucessMessage }}
    </i>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { autosize } from "@/directives/autosize";

export default {
  directives: {
    autosize,
  },
  data() {
    return {
      errors: {
        logoUrl: false,
        headerText: false,
        content: false,
      },
      showSucess: false,
      sucessMessage: "",
      showError: false,
      errorMessage: "",
      homepage: {
        logoUrl: "",
        logoAlt: "",
        headerText: "",
        content: "",
        homepageTitle: "",
        homepageMeta: "",
        googleAnalytics: "",
        facebookPixel: "",
        emailText: "This is the plaintext section of the email body.",
        emailTemplate: "This is the <code>HTML</code> section of the email body.",
        emailSubject: "Subject",
      },
    };
  },
  computed: {
    ...mapState(["homepageData"]),
    storedHomepage() {
      return this.$store.getters.homepageData;
    },
  },
  methods: {
    saveHomepage() {
      this.errors.content = false;
      this.errors.logoUrl = false;
      this.errors.headerText = false;
      if (!this.homepageData.content) {
        this.showError = true;
        this.errors.content = true;
        this.errorMessage = "Please provide content";
      } else if (!this.homepageData.headerText) {
        this.showError = true;
        this.errors.headerText = true;
        this.errorMessage = "Please provide Brand Name";
      } else {
        this.$store
          .dispatch("updateHomepage", {
            logoUrl: this.homepageData.logoUrl,
            content: this.homepageData.content,
            logoAlt: this.homepageData.headerText,
            headerText: this.homepageData.headerText,
            componentName: "EmailCollection",
            homepageTitle: this.homepageData.homepageTitle,
            homepageMeta: this.homepageData.homepageMeta,
            googleAnalytics: this.homepageData.googleAnalytics,
            facebookPixel: this.homepageData.facebookPixel,
            emailTemplate: this.homepageData.emailTemplate,
            emailText: this.homepageData.emailText,
            emailSubject: this.homepageData.emailSubject,
          })
          .then(() => {
            this.showError = false;
            this.showSucess = true;
            this.sucessMessage = "Updated!";
            setTimeout(() => {
              this.showSucess = false;
            }, 8000);
          })
          .catch((error) => {
            this.showError = true;
            this.errorMessage = error;
          });
      }
    },
  },
  mounted() {
    this.homepage = Object.assign({}, this.homepageData);
    if (this.storedHomepage) {
      this.homepage = { ...this.storedHomepage };
    }
  },
  created() {
    this.$store.dispatch("loadHomepage");
  },
};
</script>
<style scoped>
.error-input {
  border: 1px solid red;
  background-color: rgb(248, 179, 193);
}
.error-message {
  border: solid 1px #fa0335;
  /* background-color: rgb(248, 179, 193);
  border-radius: 3px;
  padding: 10px; */
}

.sucess-message {
  /* border: solid 1px #769a5e;
  background-color: rgb(168, 213, 187);
  border-radius: 3px; */
}
.content {
  width: 1000px !important;
}
.admin-container {
  width: 600px;
}
.left-description {
  flex-grow: 0;
  text-align: left;
  font-weight: 400;
  color: #637381;
  max-width: 250px;
  margin-right: 30px;
  margin-top: 40px;
}
.left-description p {
  text-align: left;
  font-weight: 500;
  color: #212b36;
  margin-bottom: 30px;
}
.editor-container {
  display: flex;
  box-sizing: border-box;
  padding: 0px;
  margin-top: 20px;
  border-bottom: #d1d1d1 1px solid;
}

.page-details {
  flex-grow: 2;
  margin-left: 25px;
  margin-bottom: 20px;
}

.page-details-bg {
  background: white;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  padding: 20px 40px;
  border-radius: 10px;
}

.page-settings {
  flex-basis: 200px;
  width: 120px;
}

.editor-container label {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.editor-container h3 {
  color: #666666;
  font-weight: 400;
}

.page-actions-container label {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #666666;
}

.page-actions-container {
  padding: 40px;
  background-color: #ffffff;
  border-radius: 10px;
}

.page-actions button {
  margin-left: 10px;
  padding: 5px 15px;
  cursor: pointer;
}

.view-button {
  color: white;
  border: none;
  border-radius: 4px;
}

.save-button {
  padding: 10px 20px;
  background: linear-gradient(135deg, #842bd7 0%, #c643fc 100%) !important;
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
  transition: all 0.3s ease;
  margin: 40px 350px 0px 0;
}

label {
  display: block;
  margin: 10px 0;
  color: #637381;
}

input[type="text"],
textarea {
  width: 100%;
  padding: 8px;
  margin-bottom: 20px;
  border: 1px solid #d3d3d3;
  border-radius: 4px;
}

select {
  width: 100%;
  padding: 8px;
  border: 1px solid #d3d3d3;
  border-radius: 4px;
  background: white;
}

.visibility-section h2,
.template-section h2 {
  color: #212b36;
  margin-bottom: 10px;
}

.visibility-options label {
  display: block;
  margin: 10px 0;
}

textarea {
  min-height: 100px;
  outline: none;
}
.header-buttons {
  display: flex;
  justify-content: space-between;
  margin: 10px 20px;
}
.header-title {
  margin: 10px 30px;
  font-weight: bold;
  font-size: 20px;
}

.header-right {
  margin-left: auto;
}

.back-button {
  padding: 6px;
  border: solid 1px #888888;
  border-radius: 5px;
  max-width: 25px;
  max-height: 25px;
}

.link-con {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.eye-icon {
  margin: 5px;
  font-weight: bold;
  flex: 1;
}

.header-view {
  flex: 1;
}

.col-top {
  margin-top: 40px;
}
</style>
