<template>
  <div class="image-view-container">
    <router-link :to="`/admin/media`"
          ><FeatherIcon name="arrow-left"
        /></router-link>
    <h1>{{ imageName }}</h1>
    <img :src="imageUrl" :alt="imageName" height="250px"/>
    <p>
      Image Link: <a :href="imageUrl" target="_blank">{{ imageUrl }}</a>
    </p>
  </div>
</template>

<script>
export default {
    props: {
    imageName: String,
    imageUrl: String
  },
};
</script>

<style scoped>
/* Add styles for the image view container */
</style>
