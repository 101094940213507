<!-- src/components/AdminPanel.vue -->
<!-- AdminPanel.vue -->
<template>
  <div class="admin-container">
    <aside class="sidebar">
      <nav class="nav-menu-a">
        <ul>
          <li>
            <router-link to="/admin" class="nav-item-a">
              <svg
                viewBox="0 0 24 24"
                stroke="currentColor"
                fill="none"
                stroke-linecap="round"
                stroke-linejoin="round"
                class="icons css-i6dzq1"
              >
                <path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z"></path>
                <polyline points="9 22 9 12 15 12 15 22"></polyline>
              </svg>
              <span class="text">Dashboard</span>
            </router-link>
          </li>
          <li>
            <router-link to="/admin/email-list" class="nav-item-a">
              <svg
                viewBox="0 0 24 24"
                stroke="currentColor"
                fill="none"
                stroke-linecap="round"
                stroke-linejoin="round"
                class="icons css-i6dzq1"
              >
                <path
                  d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z"
                ></path>
                <polyline points="22,6 12,13 2,6"></polyline>
              </svg>
              <span class="text">Email List</span>
            </router-link>
          </li>
          <li>
            <router-link to="/admin/pages" class="nav-item-a">
              <svg
                viewBox="0 0 24 24"
                stroke="currentColor"
                fill="none"
                stroke-linecap="round"
                stroke-linejoin="round"
                class="icons css-i6dzq1"
              >
                <polyline points="22 12 16 12 14 15 10 15 8 12 2 12"></polyline>
                <path
                  d="M5.45 5.11L2 12v6a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2v-6l-3.45-6.89A2 2 0 0 0 16.76 4H7.24a2 2 0 0 0-1.79 1.11z"
                ></path>
              </svg>
              <span class="text">Pages</span>
            </router-link>
          </li>
          <li>
            <router-link to="/admin/media" class="nav-item-a">
              <svg
                viewBox="0 0 24 24"
                stroke="currentColor"
                fill="none"
                stroke-linecap="round"
                stroke-linejoin="round"
                class="icons css-i6dzq1"
              >
                <rect x="3" y="3" width="18" height="18" rx="2" ry="2"></rect>
                <circle cx="8.5" cy="8.5" r="1.5"></circle>
                <polyline points="21 15 16 10 5 21"></polyline>
              </svg>
              <span class="text">Media</span>
            </router-link>
          </li>
          <li>
            <router-link to="/admin/homepage" class="nav-item-a">
              <svg
                viewBox="0 0 24 24"
                stroke="currentColor"
                fill="none"
                stroke-linecap="round"
                stroke-linejoin="round"
                class="icons css-i6dzq1"
              >
                <line x1="4" y1="21" x2="4" y2="14"></line>
                <line x1="4" y1="10" x2="4" y2="3"></line>
                <line x1="12" y1="21" x2="12" y2="12"></line>
                <line x1="12" y1="8" x2="12" y2="3"></line>
                <line x1="20" y1="21" x2="20" y2="16"></line>
                <line x1="20" y1="12" x2="20" y2="3"></line>
                <line x1="1" y1="14" x2="7" y2="14"></line>
                <line x1="9" y1="8" x2="15" y2="8"></line>
                <line x1="17" y1="16" x2="23" y2="16"></line>
              </svg>
              <span class="text">Preferences</span>
            </router-link>
          </li>
          <li>
            <a href="#" @click="logout" class="nav-item-a">
              <svg
                viewBox="0 0 24 24"
                stroke="currentColor"
                fill="none"
                stroke-linecap="round"
                stroke-linejoin="round"
                class="icons css-i6dzq1"
              >
                <path d="M9 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h4"></path>
                <polyline points="16 17 21 12 16 7"></polyline>
                <line x1="21" y1="12" x2="9" y2="12"></line>
              </svg>
              <span class="text">Logout</span>
            </a>
          </li>
        </ul>
      </nav>
    </aside>

    <div class="content">
      <router-view />
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  mounted() {
    this.$store.dispatch("loadPages");
  },
  data() {
    return {
      currentPage: null, // Initially empty until loaded
      pages: [],
    };
  },
  methods: {
    ...mapActions(["logoutUser"]),

    logout() {
      this.logoutUser().then(() => {
        // Redirect to login or another page after logout
        this.$router.push("/login");
      });
    },
  },
};
</script>
<style scoped>
body {
  background: #f5f5f5 !important;
}

.content {
  margin: 20px;
}
.admin-container {
  display: grid;
  grid-template-columns: 200px 1fr;
  min-height: 100vh;
  background: #f5f5f5 !important;
}

.sidebar {
  width: 120px;
  background-color: #f5f5f5;
  color: #999;
  padding: 20px;
  border-right: 1px solid #e1e1e1;
}

.nav-menu-a {
  list-style-type: none;
  padding: 0;
}

.nav-menu-a li {
  margin-bottom: 20px;
}

.nav-item-a {
  display: flex;
  align-items: center;
  color: inherit;
  text-decoration: none;
}

.icons {
  width: 20px;
  height: 20px;
  stroke-width: 2;
}

.text {
  font-size: 14px;
  color: #888;
  font-weight: 400;
  margin-left: 10px;
}

.nav-item-a:hover,
.text:hover {
  color: #333; /* Highlight color */
}

/* Mobile-friendly adjustments */
@media screen and (max-width: 600px) {
  .icons {
    margin: 0;
    width: 45px !important;
    height: 45px !important;
    stroke-width: 2 !important;
  }
  .sidebar {
    width: 55px; /* Narrower sidebar for icons only */
  }

  .text {
    display: none; /* Hide text on smaller screens */
  }

  .admin-container {
    grid-template-columns: 100px 1fr;
  }
}
</style>
