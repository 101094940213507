<!-- src/components/PageList.vue -->
<!-- PageList.vue -->
<template>
  <div class="page-list-container">
    <div class="toolbar">
      <div class="toolbar-left">
        <h1>Email List</h1>
      </div>
    </div>
    <div class="page-list">
      <div class="list-header">Emails Collected</div>
      <div class="list-content">
        <div class="page-item" v-for="email in paginatedEmails" :key="email.id">
          <div class="page-title">{{ email.name }}</div>
          <div class="page-meta">
            <span>{{ email.to }}&nbsp;</span>
          </div>
          <div class="page-actions">
            <span>Published {{ formatDate(email.timestamp) }}</span>
          </div>
        </div>
      </div>
    </div>

    <div class="pagination">
      <button @click="changePage(currentPage - 1)" :disabled="currentPage <= 1">
        Prev
      </button>
      <span>Page {{ currentPage }} of {{ totalPages }}</span>
      <button
        @click="changePage(currentPage + 1)"
        :disabled="currentPage >= totalPages"
      >
        Next
      </button>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      currentPage: 1,
      itemsPerPage: 3,
    };
  },
  methods: {
    changePage(newPage) {
      this.currentPage = newPage;
    },
    formatDate(timestamp) {
      const date = new Date(timestamp);
      return date.toLocaleDateString("en-US", {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        hour: '2-digit',
        minute: '2-digit'
      });
    }
  },
  computed: {
    totalPages() {
      return Math.ceil(this.emails.length / this.itemsPerPage);
    },
    emails() {
      return this.$store.getters.allEmails;
    },
    paginatedEmails() {
      let start = (this.currentPage - 1) * this.itemsPerPage;
      let end = start + this.itemsPerPage;
      return this.emails.slice(start, end);
    },
  },
};
</script>

<style>
.page-list-container {
  overflow: hidden;
}

.toolbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
}

.toolbar-left h1 {
  margin: 0;
  font-size: 24px;
}

.toolbar-right .add-blog-btn {
  padding: 10px 20px;
  background-color: #1b1b1b; /* Or any other color you prefer */
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
}

.toolbar-right .add-blog-btn:hover {
  background-color: #000000; /* Or any other color you prefer */
}

.page-list {
}

.list-header {
  background: #fff;
  border-radius: 3px;
  margin: 0px 20px;
  padding: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.list-content {
  padding: 20px;
}

.page-item {
  background: #fff;
  border-radius: 3px;
  margin-bottom: 10px;
  padding: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.page-title {
  font-weight: bold;
  /* Add additional title styles */
}

.page-meta {
  /* Styles for the metadata section */
}

.page-actions {
  /* Styles for the action buttons */
}

.page-actions button {
  background: none;
  border: none;
  margin-left: 8px;
  cursor: pointer;
}

.icon {
  width: 20px;
  height: 20px;
  stroke-width: 1.5 !important;
}

.menu-icon {
  cursor: pointer;
  /* additional styling for the icon */
}


.pagination {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.pagination button {
  padding: 10px 20px;
  margin: 0 5px;
  background: linear-gradient(135deg, #842bd7 0%, #c643fc 100%);
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 14px;
  cursor: pointer;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
  transition: all 0.3s ease;
}

.pagination button:disabled {
  background-color: #ccc;
  background: linear-gradient(135deg, #a174cb 0%, #936ea3 100%)!important;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
  cursor: default;
}

.pagination span {
  margin: 0 10px;
}

/* Mobile-friendly adjustments */
@media screen and (max-width: 600px) {

}
</style>
