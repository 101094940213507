// /firebaseConfig.js
//firebaseConfig.js

import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import { getFunctions } from 'firebase/functions';

// Your Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyCZuJ33Q1jBf0L34X1WrpQWqZkDKuxkX3c",
  authDomain: "this-is-the-mom-way.firebaseapp.com",
  databaseURL: "https://this-is-the-mom-way-default-rtdb.firebaseio.com",
  projectId: "this-is-the-mom-way",
  storageBucket: "this-is-the-mom-way.appspot.com",
  messagingSenderId: "881472587954",
  appId: "1:881472587954:web:0045fab9ef51cc55c321ab",
  measurementId: "G-RQNLXFM6V2"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const functions = getFunctions(app);
const db = getFirestore(app);
const auth = getAuth(app);



export { db, auth, functions };
