<template>
  <div class="dashboard">
    <div class="dashboard-item">
      <div class="dashboard-header">Get ready to gather leads.</div>
      <div class="dashboard-content">
        <ul class="task-list">
          <li>
            <router-link to="/admin/new-page?homepage=12">
              <span class="task-icon">
                <svg
                  viewBox="0 0 24 24"
                  width="24"
                  height="24"
                  stroke="currentColor"
                  stroke-width="1"
                  fill="none"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  class="css-i6dzq1"
                >
                  <path
                    d="M14 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8z"
                  ></path>
                  <polyline points="14 2 14 8 20 8"></polyline>
                  <line x1="12" y1="18" x2="12" y2="12"></line>
                  <line x1="9" y1="15" x2="15" y2="15"></line>
                </svg>
              </span>
              Add Pages
            </router-link>
          </li>
          <li>
            <router-link to="/admin/homepage">
              <span class="task-icon">
                <svg
                  viewBox="0 0 24 24"
                  width="24"
                  height="24"
                  stroke="currentColor"
                  stroke-width="1"
                  fill="none"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  class="css-i6dzq1"
                >
                  <path d="M12 19l7-7 3 3-7 7-3-3z"></path>
                  <path d="M18 13l-1.5-7.5L2 2l3.5 14.5L13 18l5-5z"></path>
                  <path d="M2 2l7.586 7.586"></path>
                  <circle cx="11" cy="11" r="2"></circle>
                </svg>
              </span>
              Customize landing page
            </router-link>
          </li>
          <li>
            <router-link to="/admin/email-list">
              <span class="task-icon"
                ><svg
                  viewBox="0 0 24 24"
                  width="24"
                  height="24"
                  stroke="currentColor"
                  stroke-width="1"
                  fill="none"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  class="css-i6dzq1"
                >
                  <polyline
                    points="22 12 16 12 14 15 10 15 8 12 2 12"
                  ></polyline>
                  <path
                    d="M5.45 5.11L2 12v6a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2v-6l-3.45-6.89A2 2 0 0 0 16.76 4H7.24a2 2 0 0 0-1.79 1.11z"
                  ></path>
                </svg>
              </span>
              View Email List
            </router-link>
          </li>
        </ul>
        <div class="task-detail">
          <h3>Add Page</h3>
          <p>Add Pages, Media, view Email leads, customize landing page.</p>
          <router-link to="/admin/new-page" class="button"
            >Add page</router-link
          >
          <a href=""
            ><svg
              viewBox="0 0 24 24"
              width="24"
              height="24"
              stroke="currentColor"
              stroke-width="1"
              fill="none"
              stroke-linecap="round"
              stroke-linejoin="round"
              class="css-i6dzq1"
            >
              <circle cx="12" cy="12" r="10"></circle>
              <line x1="12" y1="16" x2="12" y2="12"></line>
              <line x1="12" y1="8" x2="12.01" y2="8"></line>
            </svg>
            <p>Learn more about pages</p></a
          >
        </div>
      </div>
    </div>

    <div class="dashboard-item">
      <div class="dashboard-content">
        <div class="dashboard-header-second">
          <svg
            viewBox="0 0 24 24"
            width="24"
            height="24"
            stroke="currentColor"
            stroke-width="1"
            fill="none"
            stroke-linecap="round"
            stroke-linejoin="round"
            class="book css-i6dzq1"
          >
            <path d="M2 3h6a4 4 0 0 1 4 4v14a3 3 0 0 0-3-3H2z"></path>
            <path d="M22 3h-6a4 4 0 0 0-4 4v14a3 3 0 0 1 3-3h7z"></path>
          </svg>
          <p>SUGGESTED READING</p>
        </div>
        <div class="reading-item">
          <p>Because you started less than a year ago</p>
          <h3>
            Investing can help your business grow, but you should understand
            your finances first. Learn how with this guide.
          </h3>
          <a href="#">Read blog post</a>
          <span>10 min read</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  // Component logic goes here
};
</script>

<style scoped>
.dashboard {
  max-width: 1000px;
  margin: 0 auto;
  padding: 16px;
}

.dashboard-item {
  background: #ffffff;
  border-radius: 8px;
  overflow: hidden;
  margin-bottom: 46px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  border: #ededed solid 1px;
}

.dashboard-header {
  text-align: left;
  padding: 26px;
  font-size: 22px;
  font-weight: 500;
  color: #333333;
  border-bottom: #d1d1d1 solid 1px;
}

.dashboard-header-second {
  padding: 20px 45px;
  background-color: #fafafa;
  border-right: #d1d1d1 solid 1px;
}
p {
  font-size: 14px;
  font-weight: 500;
  color: #767676;
}

.book {
  height: 50px;
  width: 50px;
  color: #767676;
}

.dashboard-content {
  display: flex;
}

.task-list {
  padding: 0;
  margin: 0;
  list-style: none;
  text-align: left;
  background-color: #fafafa;
  border: none;
}
.task-list li {
  padding: 15px 20px;
  cursor: pointer;
  border-right: #d1d1d1 solid 1px;
}

.task-list li:hover {
  background-color: #ffffff;
  border-right: #ffffff solid 1px;
}

.task-icon {
  margin-right: 8px;
}

.task-detail {
  margin-left: 16px;
}

.task-detail h3 {
  margin: 0;
  color: #333;
  font-size: 18px;
}

.task-detail p {
  color: #666;
}

.task-detail .button {
  padding: 10px 20px !important;
  background: linear-gradient(135deg, #842bd7 0%, #c643fc 100%) !important;
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
  transition: all 0.3s ease;
  margin: 50px;
}

.task-detail a {
  color: #007bff;
  text-decoration: none;
  display: inline-block;
  margin-top: 8px;
}

.reading-item {
  padding: 20px;
}

.reading-item h3 {
  font-size: 14px;
  margin-top: 8px;
}

.reading-item p {
  color: #666;
  font-size: 12px;
}

.reading-item a {
  font-size: 14px;
  color: #007bff;
  text-decoration: none;
  margin-right: 8px;
}

.reading-item span {
  font-size: 12px;
  color: #999;
}
</style>
