<template>
    <div class="dialog-background">
      <div class="dialog">
        <div class="dialog-header">
          <h3>{{ titleText }}</h3>
          <a @click="$emit('close')">
            <FeatherIcon name="x" />
          </a>
        </div><p>{{ bodyText }}</p>
        <div class="dialog-footer">
          <a @click="$emit('close')">Cancel</a>
          <button @click="$emit('confirmButton')">
            <span>{{ buttonText }}</span>
          </button>
        </div>
      </div>
    </div>
  </template>
  
  <script>  
  export default {
    components: {},
    props: {
    titleText: {
      type: String,
      required: true,
      default: 'Title Here'
    },
    bodyText: {
        type: String,
        required: true,
        default: 'Body Text Here'
    },
    buttonText: {
      type: String,
      required: true,
      default: 'Upload',
    },
  },
  };
  </script>
  
  <style scoped>
  .dialog-background {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .dialog {
    background-color: white;
    border-radius: 6px;
    width: 800px;
  }
  
  .dialog-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    padding: 20px;
  }
  
  .dialog-header h3 {
    color: #637381;
    font-size: 18px;
  }
  
  .dialog-header a {
    cursor: pointer;
    color: #666666;
  }
  
  .upload-area {
    border: 3px dashed #d2e5f2;
    background-color: #f6fafa;
    padding: 20px;
    text-align: center;
  }
  
  .a-browse {
    color: #842bd7;
    cursor: pointer;
  }
  
  .dialog-footer {
    background-color: #f5f5f5;
    display: flex;
    justify-content: space-between;
    margin-top: 50px;
    border-top: 1px solid #cfcfcf;
    padding: 20px;
    border-bottom-right-radius: 6px;
    border-bottom-left-radius: 6px;
  }
  
  .dialog-footer a {
    color: #444;
    cursor: pointer;
    margin-top: 10px;
  }
  
  .dialog-footer button {
    padding: 10px 20px;
    background: linear-gradient(135deg, #842bd7 0%, #c643fc 100%) !important;
    color: white;
    border: none;
    border-radius: 4px;
    font-size: 16px;
    cursor: pointer;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
    transition: all 0.3s ease;
  }
  
  .loading-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(255, 255, 255, 0.8);
  }
  
  .loading-icon {
    animation: spin 2s linear infinite;
  }
  
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  </style>
  