<template>
  <div class="media-container">
    <h1>Media Library</h1>
    <div class="dialog-con">
      <button @click="showDialog = true" class="add-image-btn">
        <svg
          viewBox="0 0 24 24"
          width="24"
          height="24"
          stroke="currentColor"
          stroke-width="1"
          fill="none"
          stroke-linecap="round"
          stroke-linejoin="round"
          class="icon css-i6dzq1"
        >
          <path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4"></path>
          <polyline points="17 8 12 3 7 8"></polyline>
          <line x1="12" y1="3" x2="12" y2="15"></line>
        </svg>
        &nbsp;Add Image
      </button>
      <ImageUploadDialog
        v-if="showDialog"
        @close="showDialog = false"
        @upload="uploadImages"
      />
    </div>
    <div  class="media-grid">
      <!-- Grid view for desktop -->
      <div class="image-thumbnail" v-for="image in images" :key="image.name">
        <img :src="image.url" :alt="image.name" @click="goToImageView(image)" />
        <confirm-dialog
          v-if="displayDialog"
          :titleText="dialogTitle"
          :button-text="dialogButtonText"
          :body-text="dialogBody"
          @close="handleClose"
          @confirmButton="deleteImage(image.name)"
        />
        <button class="delete-icon" @click="handleConfirm(image)">
          <svg
            viewBox="0 0 24 24"
            width="24"
            height="24"
            stroke="currentColor"
            stroke-width="1"
            fill="none"
            stroke-linecap="round"
            stroke-linejoin="round"
            class="icon css-i6dzq1"
          >
            <rect x="3" y="3" width="18" height="18" rx="2" ry="2"></rect>
            <line x1="9" y1="9" x2="15" y2="15"></line>
            <line x1="15" y1="9" x2="9" y2="15"></line>
          </svg>
        </button>
      </div>
    </div>
   
  </div>
</template>

<script>
import { useRouter } from "vue-router";
import { ref, computed, onMounted } from "vue";
import ConfirmDialog from "./ConfirmDialog.vue";
import {
  getFirestore,
  collection,
  addDoc,
  deleteDoc,
  query,
  where,
  getDocs,
} from "firebase/firestore";
import {
  getStorage,
  ref as storageRef,
  uploadBytes,
  listAll,
  getDownloadURL,
  deleteObject,
} from "firebase/storage";
import { v4 as uuidv4 } from "uuid";
import ImageUploadDialog from "./ImageUploadDialog.vue";

export default {
  components: {
    ImageUploadDialog,
    ConfirmDialog,
  },
  setup() {
    const router = useRouter();
    const images = ref([]);
    const isMobile = computed(() => window.innerWidth < 768);
    const storage = getStorage();
    const showDialog = ref(false);
    const db = getFirestore();
    const displayDialog = ref(false);
    const dialogTitle = ref("Confirm Deletion");
    const dialogButtonText = ref("Delete Page");
    const dialogBody = ref("Are you sure that you wan to delete this image?");
    const currentImage = ref(null);


    const fetchImages = async () => {
      const listRef = storageRef(storage, "images/");
      listAll(listRef)
        .then((res) => {
          res.items.forEach((itemRef) => {
            getDownloadURL(itemRef).then((downloadURL) => {
              images.value.push({ name: itemRef.name, url: downloadURL });
            });
          });
        })
        .catch((error) => {
          console.error("Error fetching images:", error);
        });
    };

    const goToImageView = (image) => {
      router.push({
        name: "ImageView",
        params: { imageName: image.name },
        query: { imageUrl: image.url },
      });
    };

    const handleConfirm = (image) => {
      console.log("dialog"+image.name);
      currentImage.value = image; 
      displayDialog.value = true;
    };

    const handleClose = () => {
      displayDialog.value = false;
    };

    const uploadImages = async (files) => {
      for (let file of files) {
        const uniqueFileName = uuidv4() + "_" + uuidv4();
        const storageReference = storageRef(
          storage,
          `images/${uniqueFileName}`
        );
        await uploadBytes(storageReference, file).then((snapshot) => {
          getDownloadURL(snapshot.ref).then((downloadURL) => {
            images.value.push({ name: uniqueFileName, url: downloadURL });
            addDoc(collection(db, "images"), {
              name: uniqueFileName,
              url: downloadURL,
              timestamp: Date.now(),
            });
          });
        });
      }
      showDialog.value = false;
    };

    const deleteImage = async (imageName) => {
      imageName = currentImage.value.name;
      const imageRef = storageRef(storage, `images/${imageName}`);
        try {
          // Delete the image from Firebase Storage
          await deleteObject(imageRef);

          // Query Firestore to find the document with the matching 'name'
          const q = query(
            collection(db, "images"),
            where("name", "==", imageName)
          );
          const querySnapshot = await getDocs(q);
          
          querySnapshot.forEach(async (doc) => {
            // Delete the document from Firestore
            
            await deleteDoc(doc.ref);
            
          });

          // Update the local images array
          images.value = images.value.filter(
            (image) => image.name !== imageName
          );

          
          displayDialog.value = false;
        } catch (error) {
          console.error("Error deleting image:", error);
        }
      
    };

    onMounted(fetchImages);

    return {
      images,
      isMobile,
      showDialog,
      uploadImages,
      deleteImage,
      goToImageView,
      displayDialog,
      dialogTitle,
      dialogButtonText,
      dialogBody,
      handleClose,
      handleConfirm,
    };
  },
};
</script>

<style scoped>
.media-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 20px;
}

.image-thumbnail {
  position: relative;
  background-color: #ffffff;
  border-radius: 4px;
  width: 200px;
  height: 200px;
  overflow: hidden;
  padding: 8px;
  cursor: pointer;
}

.image-thumbnail img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.delete-icon {
  position: absolute;
  top: 0;
  right: 0;
  border: none;
  cursor: pointer;
}

.media-list .image-list-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px;
  border-bottom: 1px solid #ccc;
}

.media-list {
  display: block;
}

.image-list-item {
  margin-bottom: 10px;
}

.delete-button {
  background-color: transparent;
  border: none;
  color: red;
  cursor: pointer;
}

.mobile-view {
  display: block;
}

.dialog-con {
  margin: 30px 0;
}

.add-image-btn {
  padding: 10px 20px;
  background: linear-gradient(135deg, #842bd7 0%, #c643fc 100%) !important;
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
  transition: all 0.3s ease;
}
</style>
