// actions.js
import { auth, db } from "@/firebaseConfig";
import { signInWithEmailAndPassword, signOut } from "firebase/auth";
import {
  collection,
  getDoc,
  getDocs,
  addDoc,
  updateDoc,
  doc,
  deleteDoc,
  query, 
  where
  //setDoc,
} from "firebase/firestore";

export default {
  async loadPages({ commit }) {
    const querySnapshot = await getDocs(collection(db, "pages"));
    const pages = querySnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));
    commit("updatePages", pages);
  },
  async loadEmails({ commit }) {
    const querySnapshot = await getDocs(collection(db, "emails"));
    const emails = querySnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));
    commit("updateEmails", emails);
  },
  async loadPageSlugs({ commit }) {
    try {
      // const querySnapshot = await getDocs(collection(db, "pages"));
      // const pageSlugs = querySnapshot.docs.map((doc) => doc.data().pageSlug);
      const querySnapshot = await getDocs(
        query(collection(db, "pages"), where("visibility", "==", "true"))
      );
      
     
      const pageSlugs = querySnapshot.docs.map((doc) => doc.data().pageSlug);
      const pageNav = querySnapshot.docs.map((doc) => doc.data().navVisibility);
      console.log(pageSlugs);
      commit("setPageNav", pageNav);
      commit("setPageSlugs", pageSlugs);

    } catch (error) {
      console.error("Error fetching page slugs:", error);
    }
  },
  async loadHomepage({ commit }) {
    try {
      const docRef = doc(db, "settings/homepage");
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        const homepageData = docSnap.data();
        commit("updateHomepage", homepageData);
      } else {
        console.log("No such document!");
      }
    } catch (error) {
      console.error("Error fetching homepage data:", error);
    }
  },
  // async loadHomepageContent({ commit }) {
  //   try {
  //     // const homePageData = await db.collection("settings").doc("homepage").collection("content").get();
  //     // let htmlContent = "";
  //     // homePageData.forEach(doc => {
  //     //   htmlContent += doc.data().html; // Assuming each document has an 'html' field
  //     // });
  //     const homepageContent = await getDoc(doc(db, 'settings/homepage'));
  //     if (homepageContent.exists()) {
  //       console.log(homepageContent.data().html);
  //       commit('setHomePageContent', homepageContent.data());
  //     } else {
  //       console.log("No such document!");
  //     }
  //   } catch (error) {
  //     console.error("Error fetching homepage data:", error);
  //   }


  // },
    // Fetch homepage content and commit to state
    async loadHomepageContent({ commit }) {
      const docRef = doc(db, "settings", "homepage");
      try {
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          // Check if the 'content' field exists
          const content = docSnap.data().content;
          if (content) {
            // If it exists, commit it to the store
            commit("setHomePageContent", content);
          } else {
            console.error("'content' field does not exist in the document.");
          }
        } else {
          console.error("No such document!");
        }
      } catch (error) {
        console.error("Error fetching homepage data:", error);
      }
    },
  async loginUser({ commit }, credentials) {
    try {
      const userCredential = await signInWithEmailAndPassword(
        auth,
        credentials.email,
        credentials.password
      );
      // If login is successful
      commit("setAuthentication", true);
      //commit('setUser', userCredential.user); // Optionally store user data
      console.log("user set");
      console.log(userCredential.user);
      console.log("Action: loginUser", credentials);
    } catch (error) {
      console.error("Login Error:", error);
      // Handle errors (like showing messages to the user)
      commit("setAuthentication", false);
    }
  },
  async addPage(_, newPage) {
    try {
      console.log(newPage);
      await addDoc(collection(db, "pages"), newPage);
      // Optionally commit to the store or fetch pages again
    } catch (error) {
      console.error("Error adding page:", error);
    }
  },
  async removePage({ commit }, pageId) {
    try {
      const docRef = doc(db, "pages", pageId);
      await deleteDoc(docRef); // Delete the document from Firestore
      commit("removePage", pageId); // Update Vuex state
    } catch (error) {
      console.error("Error removing page:", error);
      // Handle errors appropriately, e.g., display error messages
    }
  },
  //Get page by page id
  async getPageById({ commit }, pageId) {
    try {
      const docRef = doc(db, "pages", pageId);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        const page = { id: docSnap.id, ...docSnap.data() };
        commit("setCurrentPage", page);
      } else {
        console.log("No such document!");
      }
    } catch (error) {
      console.error("Error fetching page:", error);
    }
  },
  //Update page info
  async updatePage(_, updatePage) {
    try {
      console.log(updatePage);
      const pageRef = doc(db, "pages", updatePage.id);
      await updateDoc(pageRef, updatePage);
    } catch (error) {
      console.error("Error adding page:", error);
    }
  },
  async updateHomepage({ commit }, updatedSettings) {
    const docRef = doc(db, "settings", "homepage");
    try {
      const docSnap = await getDoc(docRef);
      if (!docSnap.exists()) {
        console.error("Document does not exist!");
        // Handle the error: maybe dispatch an action to set the default settings
      } else {
        await updateDoc(docRef, updatedSettings);
        commit("setHomepage", updatedSettings);

        // Commit any mutations if necessary, or handle the successful update
      }
    } catch (error) {
      console.error("Error updating homepage:", error);
      // Handle the error: maybe set an error state in your store
    }
  },
  logoutUser({ commit }) {
    signOut(auth)
      .then(() => {
        commit("setAuthentication", false);
        console.log("User logged out successfully");
      })
      .catch((error) => {
        console.error("Logout Error:", error);
        // Handle any errors that occur during logout
      });
  },
};
