<template>
  <div class="body-color">

    <metainfo></metainfo>
    <!-- <div v-if="homepageData">
      <h1>{{ homepageData.headerText }}</h1>
      <div v-html="homepageData.content"></div>
      <component :is="homepageData.componentName" />
    </div>
    <div v-else>Loading homepage data...</div> -->

    <div class="container">
      <div class="headline">
        Discover How A 26-Year Old Made $14,975.43 Last Month By Sending Cover
        Letters In A Freelance Marketplace
      </div>
      <div class="main-content">
        <img
          src="https://firebasestorage.googleapis.com/v0/b/this-is-the-mom-way.appspot.com/o/images%2F17df73a3-9414-4e6a-97ee-20d107fd9f64_c12e2f99-c024-4790-b5ee-b768d7d1f0dc?alt=media&token=43490ebc-e379-422c-90bb-255d5498cfdc"
          alt="Woman"
          class="woman-image"
        />

        <div class="offer-card">
          <span class="grab">GRAB MY FREE</span>
          <span class="report">REPORT</span>
          <p>
            Etiam eu aliquet arcu. Aenean quis dictum dui, mattis elementum
            purus. Vivamus condimentum...
          </p>

          <EmailCollection @emailSubmitted="handleEmailSubmission" />
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
//import { computed } from 'vue';
import { useStore } from "vuex";
import { onMounted } from "vue";
import EmailCollection from "./EmailCollection.vue";
// import { useMeta } from 'vue-meta';
const store = useStore();



// Access homepageData using computed property

//document.title = store.state.homepageData.homepageTitle;

// Load homepage data in `onMounted` hook
onMounted(() => {
  //const meta = useMeta();

//const pageTitle = computed(() => store.state.pageTitle);

//meta.title.value = pageTitle.value;
  store.dispatch("loadHomepage").then(() => {
    
    document.title = store.state.homepageData.homepageTitle;
// document.querySelector('meta[name="description"]').content = 'a';
// document.querySelector('meta[name="keywords"]').content = 'ca';

  });
});

// Handle email submission
const handleEmailSubmission = () => {
  console.log("Email collected!");
  // Handle email submission events as needed
};
</script>
<!-- <script>
import { mapState } from "vuex";
import EmailCollection from "./EmailCollection.vue";



export default {
  components: {
    EmailCollection,
  },
  data() {
    return {
      pageTitle: "",
    };
  },
  mounted() {
    
  },
  watch: {

  },
  computed: {
    ...mapState(["homepageData"]),
    
  },
  created() {
    this.$store.dispatch("loadHomepage");
  },
  methods: {
    handleEmailSubmission() {
      console.log("Email collected!");
      // Handle email submission events as needed
    },
  },
};
</script> -->
<style scoped>

body {
  background: #fff4a2 !important;
  font-family: "Open Sans", sans-serif !important;
}
.body-color {
  background: #fff4a2 !important;
  font-family: "Open Sans", sans-serif !important;
}
.container {
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;
  padding: 0px 0px;
  background: #fff4a2 !important;
}
.main-content {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  gap: 10px;
  padding: 0px 20px;
  flex-wrap: wrap;
}
.headline {
  font-size: 30px;
  font-weight: 500;
  color: #6f009f;
  text-align: left;
  margin-bottom: 40px;
  max-width: 70%;
  padding-top: 25px;
  margin: 0 auto;
}
.offer-card {
  flex: 1;
  max-width: 500px;
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 40px;
  margin-top: 200px;
  margin-bottom: 100px;
  text-align: center;
}
.offer-card .grab {
  font-size: 48px;
  color: #6f009f;
  display: block;
  font-weight: 600;
}
.offer-card .report {
  font-size: 110px;
  color: #6f009f;
  font-weight: 600;
}
.offer-card p {
  font-size: 26px;
  font-weight: 300;
  text-align: center;
  color: #6f009f;
  margin-bottom: 30px;
}
.email-signup {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  margin-bottom: 20px;
}
.woman-image {
  flex-shrink: 1;
  max-width: 50%;
  width: 600px;
  height: auto;
  border-radius: 8px;
}
@media (max-width: 768px) {
  .main-content {
    flex-direction: column;
    text-align: center;
  }
  .email-signup {
    max-width: 100%;
  }

  .offer-card p { 
    margin: 0 20px;;
  }

  .offer-card {
    flex: 1;
    background: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    padding: 0px;
    margin-top: 10px;
    text-align: left;
  }

  .email-signup input {
    width: 50px !important;
  }
  .woman-image {
    max-width: 80%;
    margin-bottom: 20px;
  }
  .offer-card {
    text-align: center;
    margin-top: 0px;
  }

  .offer-card .grab {
    margin-top: 20px;
    font-size: 28px;
  }
  .offer-card .report {
    font-size: 70px;
    padding-top: 20px;
  }
  .offer-card p {
    font-size: 16px;
    margin-bottom: 10px;
  }
}
</style>
