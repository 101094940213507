import { createRouter, createWebHistory } from "vue-router";
import store from "../store"; // Assuming you have a Vuex store

// Import your components
import HomePage from "../components/HomePage.vue";
import About from "../components/AboutPage.vue";
import LoginPage from "../components/LoginPage.vue";
import AdminPanel from "../components/AdminPanel.vue";
import PageList from "../components/PageList.vue";
import PageEditor from "../components/PageEditor.vue";
import HomepageEditor from "../components/HomepageEditor.vue";
import NewPage from "../components/NewPage.vue";
import PageComponent from "../components/PageComponent.vue";
import NotFoundComponent from "../components/NotFoundComponent.vue";
import AdminMedia from "../components/AdminMedia.vue";
import ImageView from "@/components/ImageView.vue";
import EmailList from "@/components/EmailList.vue";
import DashBoard from "@/components/DashBoard.vue";


const routes = [
  { path: "/:pageSlug", component: PageComponent },
  { path: "/", component: HomePage },
  { path: "/about", component: About },
  { path: "/login", component: LoginPage },
  {
    path: "/admin/adex",
    component: AdminPanel,
    children: [
      { path: "/admin", component: DashBoard },
      { path: "/admin/media", component: AdminMedia },
      { path: "/admin/new-page", component: NewPage },
      { path: "/admin/pages", component: PageList },
      { path: "/admin/pages/edit/:id", component: PageEditor },
      { path: "/admin/email-list", component: EmailList },
      { path: "/admin/homepage", component: HomepageEditor },
      {
        path: "/admin/media/image-view/:imageName/",
        name: "ImageView",
        component: ImageView,
        props: (route) => ({
          imageName: route.params.imageName,
          imageUrl: route.query.imageUrl,
        }),
      },
    ],
    meta: { requiresAuth: true }, // Admin paths require authentication
  },
  {
    path: "/:catchAll(.*)*",
    name: "NotFound",
    component: NotFoundComponent,
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

// Navigation Guard
router.beforeEach((to, from, next) => {
  const requiresAuth = to.matched.some((record) => record.meta.requiresAuth);
  const isAuthenticated = store.getters.isAuthenticated;

  // If the route requires authentication and the user is not authenticated,
  // redirect to the login page.
  if (requiresAuth && !isAuthenticated) {
    next("/login");
    return; // Prevent further execution after redirection
  }

  // If navigating to the PageList, dispatch the 'loadPages' action.
  // This is done after checking for authentication to ensure only authenticated users fetch pages.
  if (to.path === "/admin/pages") {
    store.dispatch("loadPages").then(() => next());
  } else if (to.path === "/admin/email-list") {
    store.dispatch("loadEmails").then(() => next());
  } else {
    // For all other cases, simply proceed with the navigation.
    next();
  }
});

export default router;
