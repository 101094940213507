//src/store/state.js
//state.js

export default {
  pages: [],
  emails: [],
  currentPage: null,
  homepage: {},
  user: null, // Track the current user
  isAuthenticated: false, // Tracks if the user is authenticated
  homepageData: {
    logoUrl: "",
    logoAlt: "",
    headerText: "",
    content: "",
  },
  homePageDataContnent: {},
};
