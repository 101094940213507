<!-- src/components/PageList.vue -->
<!-- PageList.vue -->
<template>
  <div class="page-list-container">
    <div class="toolbar">
      <div class="toolbar-left">
        <h1>Page List</h1>
      </div>
      <div class="toolbar-right">
        <button @click="addNewPage" class="add-blog-btn">
          + Create New Page
        </button>
      </div>
    </div>
    <div class="page-list">
      <div class="list-header">
        <div class="sort-button" @click="toggleSort">
          Last Modified

          <svg
            v-if="sortOrder === 'asc'"
            viewBox="0 0 24 24"
            width="24"
            height="24"
            stroke="currentColor"
            stroke-width="1"
            fill="none"
            stroke-linecap="round"
            stroke-linejoin="round"
            class="icon chevron-up css-i6dzq1"
          >
            <polyline points="18 15 12 9 6 15"></polyline>
          </svg>

          <svg
            v-else
            viewBox="0 0 24 24"
            width="24"
            height="24"
            stroke="currentColor"
            stroke-width="1"
            fill="none"
            stroke-linecap="round"
            stroke-linejoin="round"
            class="css-i6dzq1"
          >
            <polyline points="6 9 12 15 18 9"></polyline>
          </svg>
        </div>
      </div>
      <div class="list-content">
        <div class="page-item">
          <div class="page-title h">Landing Page {{ homepage.headerText }}</div>
          <div class="page-meta h"></div>
          <div class="page-actions h">
            <button @click="editPage('homepage')" class="action-btn edit">
              <svg
                viewBox="0 0 24 24"
                width="24"
                height="24"
                stroke="currentColor"
                stroke-width="1"
                fill="none"
                stroke-linecap="round"
                stroke-linejoin="round"
                class="css-i6dzq1"
              >
                <path
                  d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7"
                ></path>
                <path
                  d="M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z"
                ></path>
              </svg>
            </button>
            <button @click="viewPage('')" class="action-btn view">
              <svg
                viewBox="0 0 24 24"
                width="24"
                height="24"
                stroke="currentColor"
                stroke-width="1"
                fill="none"
                stroke-linecap="round"
                stroke-linejoin="round"
                class="css-i6dzq1"
              >
                <path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z"></path>
                <circle cx="12" cy="12" r="3"></circle>
              </svg>
            </button>
          </div>
        </div>

        <div class="page-item" v-for="page in sortedPages" :key="page.id">
          <div class="page-title">{{ page.title }}</div>
          <div class="page-meta">
            <span>{{ page.status }}</span>
            <span @click="closeTooltip">Published {{ page.updatedDate }}</span>
          </div>
          <div class="page-actions">
            <div class="menu-icon" @click="toggleTooltip(page.id)">
              <svg
                viewBox="0 0 24 24"
                width="32"
                height="32"
                stroke="currentColor"
                stroke-width="1"
                fill="none"
                stroke-linecap="round"
                stroke-linejoin="round"
                class="icon css-i6dzq1"
              >
                <circle cx="12" cy="12" r="1"></circle>
                <circle cx="12" cy="5" r="1"></circle>
                <circle cx="12" cy="19" r="1"></circle>
              </svg>
            </div>
            <div
              v-if="showTooltip === page.id"
              ref="`tooltip-${page.id}`"
              class="tooltip"
            >
              <a @click="viewPage(page.pageSlug)">View</a>
              <a @click="editPage(page.id)">Edit</a>
              <a @click="handleConfirm()">Delete</a>
              <confirm-dialog
                v-if="showDialogC"
                :titleText="dialogTitle"
                :button-text="dialogButtonText"
                :body-text="dialogBody"
                @close="handleClose"
                @confirmButton="deletePage(page.id) "
              />
            </div>
            <!-- <button @click="editPage(page.id)" class="action-btn edit">
              <FeatherIcon class="edit-icon" name="edit" />
            </button>
            <button @click="viewPage(page.pageSlug)" class="action-btn view">
              <FeatherIcon class="eye-icon" name="eye" />
            </button>
            <button @click="deletePage(page.id)" class="action-btn delete">
              <FeatherIcon class="trash-icon" name="trash" />
            </button> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ConfirmDialog from "./ConfirmDialog.vue";

export default {
  components: {
    ConfirmDialog,
  },
  data() {
    return {
      sortOrder: "desc",
      showTooltip: null,
      showDialogC: false,
      dialogTitle: "Confirm Deletion",
      dialogButtonText: "Delete Page",
      dialogBody: "Are you sure tha you want to delete this page?",
      currentPage: null,
      homepage: {
        logoUrl: "",
        logoAlt: "",
        headerText: "",
      },
    };
  },
  computed: {
    storedHomepage() {
      return this.$store.getters.homepageData;
    },
    pages() {
      return this.$store.getters.allPages;
    },
    sortedPages() {
      return this.pages.slice().sort((a, b) => {
        const dateA = new Date(a.updatedDate);
        const dateB = new Date(b.updatedDate);
        return this.sortOrder === "asc" ? dateA - dateB : dateB - dateA;
      });
    },
  },
  methods: {
    openDialog() {
      //this.showDialog = true;
    },
    handleClose() {
      this.showDialogC = false;
    },
    handleConfirm() {
      this.showDialogC = true;
    },
    toggleSort() {
      this.sortOrder = this.sortOrder === "asc" ? "desc" : "asc";
    },
    toggleTooltip(pageId) {
      this.showTooltip = this.showTooltip === pageId ? null : pageId;
    },
    addNewPage() {
      this.$router.push({ path: "/admin/new-page" });
    },
    editPage(pageId) {
      if (pageId == "homepage") {
        this.$router.push({ path: "/admin/homepage" });
      } else {
        this.$router.push({ path: `pages/edit/${pageId}` });
      }
    },
    viewPage(pageSlug) {
      this.$store.dispatch("getPageById", pageSlug);
      this.$router.push({ path: `/${pageSlug}` });
    },
    deletePage(pageId) {
      this.$store.dispatch("removePage", pageId);
      this.showTooltip = null;
      this.showDialog = false;
    },
    formatDate(date) {
      return date;
    },
    closeTooltip() {
      this.showTooltip = null;
    },

    handleClickOutside(event) {
      if (
        this.showTooltip &&
        this.$refs[`tooltip-${this.showTooltip}`] &&
        !this.$refs[`tooltip-${this.showTooltip}`].contains(event.target)
      ) {
        this.closeTooltip();
      }
    },
  },
  mounted() {
    window.addEventListener("click", this.handleClickOutside);
  },

  beforeUnmount() {
    window.removeEventListener("click", this.handleClickOutside);
  },
};
</script>

<style>
.page-list-container {
  overflow: hidden;
}

.toolbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
}

.toolbar-left h1 {
  margin: 0;
  font-size: 24px;
}

.toolbar-right .add-blog-btn {
  padding: 10px 20px;
  background: linear-gradient(135deg, #842bd7 0%, #c643fc 100%) !important;
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
  transition: all 0.3s ease;
}

.toolbar-right .add-blog-btn:hover {
  background-color: #303030; /* Or any other color you prefer */
}

.page-list {
}

.list-header {
  background: #fff;
  border-radius: 3px;
  margin: 0px 20px;
  padding: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.list-content {
  padding: 20px;
}

.page-item {
  background: #fff;
  border-radius: 3px;
  margin-bottom: 10px;
  padding: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.page-title {
  font-weight: bold;
  color: #637381;
}

.page-meta {
  /* Styles for the metadata section */
}

.page-actions {
  /* Styles for the action buttons */
}

.page-actions button {
  background: none;
  border: none;
  margin-left: 8px;
  cursor: pointer;
}

.icon {
  width: 20px;
  height: 20px;
  stroke-width: 1.5 !important;
}

.menu-icon {
  cursor: pointer;
  /* additional styling for the icon */
}

.tooltip {
  position: absolute;
  background-color: white;
  border: 1px solid #ccc;
  cursor: pointer;
  padding: 10px;
  border-radius: 6px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.tooltip a {
  display: block;
  color: black;
  padding: 5px;
  text-decoration: none;
}

.tooltip a:hover {
  background-color: #f5f5f5;
}

.sort-button {
  cursor: pointer;
  display: flex;
  align-items: center;
}

.icon {
  margin-left: 5px;
}

.chevron-up {
  /* style for up chevron icon */
}

.chevron-down {
  /* style for down chevron icon */
}
</style>
