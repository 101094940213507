<template>
  <i v-html="$feather.icons[name].toSvg()"></i>
</template>

<script>
export default {
  props: {
    name: {
      type: String,
      required: true,
    },
  },
};
</script>
