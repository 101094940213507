<template>
  <div class="email-signup">
    <input
      v-model="name"
      type="text"
      placeholder="Your Name Here..."
      class="email-signup"
    />
    <input
      v-model="email"
      type="text"
      placeholder="Your Email Address Here..."
      class="email-signup"
    />
    <input
      v-model="phone"
      type="text"
      placeholder="Your Phone Number Here..."
      class="email-signup"
    />
    <button @click="submitEmail" :disabled="submitting" class="email-signup">
      Send Me It To Me Now!
    </button>
    <p v-if="emailSent">Email sent!</p>
    <div v-if="error" class="error-message">
      {{ error }}
    </div>
  </div>
</template>

<script>
import { db } from "@/firebaseConfig";
import { collection, addDoc } from "firebase/firestore";
import { mapState } from "vuex";

export default {
  name: "EmailCollection",
  data() {
    return {
      name: "",
      phone: "",
      email: "",
      submitting: false,
      error: "",
      emailSent: "",
    };
  },
  methods: {
    async submitEmail() {
      this.submitting = true;
      this.error = "";
      if (!this.name && !this.email) {
        this.error = "Fill in all fields.";
      } else {
        try {
          await addDoc(collection(db, "emails"), {
            name: this.name,
            email: this.email,
            phone: this.phone,
            to: this.email,
            from: "Natalie <no-reply@thisisthemomway.com>",
            timestamp: Date.now(),
            message: {
              subject: this.homepageData.emailSubject,
              text: this.homepageData.emailText,
              html: this.homepageData.emailTemplate,
            },
          });
          this.emailSent = "yes";
          this.name = "";
          this.email = "";
          this.phone = "";
          this.$emit("emailSubmitted"); // Optional: Emit an event to notify parent components
          this.$router.push("/thankyou");
        } catch (error) {
          console.error("Error submitting email:", error);
          this.error = "Failed to submit email. Please try again.";
        } finally {
          this.submitting = false;
          setTimeout(() => {
            this.emailSent = "";
          }, 3000);
        }
      }
    },
  },
  computed: {
    ...mapState(["homepageData"]),
  },
  created() {
    this.$store.dispatch("loadHomepage");
  },
};
</script>
<style scoped>
.email-signup input {
  padding: 15px;
  border: 2px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
  width: 100%;
}
.email-signup button {
  padding: 25px 80px !important;
  background: linear-gradient(135deg, #842bd7 0%, #c643fc 100%) !important;
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 26px;
  cursor: pointer;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
  transition: all 0.3s ease;
  width: 100%;
}
.email-signup button:hover {
  box-shadow: 0 6px 8px rgba(0, 0, 0, 0.3) !important;
}

@media (max-width: 768px) {
  .email-signup input {
    width: 240px !important;
  }
  .email-signup button {
    padding: 30px !important;
    width: 70%;
    font-size: 16px;
  }
}
</style>
