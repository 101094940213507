<template>
  <header class="header">
    <div class="header-content page-width">
      <div class="header-brand">
        <div v-if="isImageUrlValid && homepageData.logoUrl">
          <img
            :src="homepageData.logoUrl"
            :alt="homepageData.logoAlt"
            height="50px"
          />
        </div>
        <div v-else>
          <router-link to="/" class="brand-link">
            {{ homepageData.headerText }}
          </router-link>
        </div>
      </div>

      <!-- Navigation Links -->
      <nav class="header-nav">
        <!-- <router-link to="/" class="nav-item">Home</router-link> -->
        <router-link
          v-for="slug in filteredSlugs"
          :key="slug"
          :to="`/${slug}`"
          class="nav-item"
          >{{ slug }}</router-link
        >
        <!-- <router-link to="/about" class="nav-item">About</router-link> -->
      </nav>

      <div class="header-social">
        <!-- Social icons or account links here -->
      </div>
    </div>
  </header>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "HeaderComponent",
  data() {
    return {
      isImageUrlValid: false,
    };
  },
  computed: {
    ...mapState(["homepageData", "pageSlugs", "pageNav"]),
    filteredSlugs() {
      return this.pageSlugs
        ? this.pageSlugs.filter((slug, index) => this.pageNav[index] === true)
        : [];
    },
  },
  methods: {
    checkImageUrl(url) {
      return new Promise((resolve, reject) => {
        const img = new Image();
        img.onload = () => resolve(true);
        img.onerror = () => reject(false);
        img.src = url;
      });
    },
  },
  mounted() {
    this.checkImageUrl(this.homepageData.logoUrl)
      .then(() => {
        this.isImageUrlValid = true;
      })
      .catch(() => {
        this.isImageUrlValid = false;
      });
  },
  created() {
    this.$store.dispatch("loadHomepage");
    this.$store.dispatch("loadPageSlugs");
  },
  watch: {
    "homepageData.logoUrl": function (newUrl) {
      if (newUrl) {
        this.checkImageUrl(newUrl)
          .then(() => {
            this.isImageUrlValid = true;
          })
          .catch(() => {
            this.isImageUrlValid = false;
          });
      } else {
        this.isImageUrlValid = false;
      }
    },
  },
};
</script>
<style>
nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #333;
}

nav a.router-link-exact-active {
  color: #333333;
}
.header {
  background-color: #ffffff; /* Light background */
  padding: 1rem 0;
  border-bottom: 1px solid #e1e1e1;
}

.page-width {
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header-brand {
  /* Styling for the brand/logo area */
}

.brand-link {
  font-size: 1.5rem;
  font-weight: bold;
  color: #333;
  text-decoration: none;
}

.header-nav {
  /* Styling for the navigation bar */
}

.nav-item {
  margin: 0 1rem;
  color: #777777;
  text-decoration: none;
  font-weight: 500;
}

.header-social {
  /* Styling for social/account links */
}

/* Responsive Design */
@media (max-width: 768px) {
  .header-content {
    flex-direction: column;
    text-align: center;
  }

  .header-nav {
    margin-top: 1rem;
  }
}
</style>
