//src/store/getters.js
// getters.js
const getters = {
    allPages: state => state.pages,
    allEmails: state => state.emails,
    currentPage: state => state.currentPage,
    homepageData: state => state.homepage,
    homepageContent: state => state.homepage.content,
    isAuthenticated: state => state.isAuthenticated,
  };
  
  export default getters;
  