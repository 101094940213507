<template>
  <div>
    <header-component/>
    <router-view/>
</div>
</template>
<script>
import HeaderComponent from './components/HeaderComponent.vue';
export default {
  components: {
    HeaderComponent
  }
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  text-align: center;
}
</style>
