//src/store/mutations.js
// mutations.js
export default {
  updatePages(state, pages) {
    state.pages = pages;
  },
  updateEmails(state, emails) {
    state.emails = emails;
  },
  updateCurrentPage(state, page) {
    state.currentPage = page;
  },
  updateHomepage(state, data) {
    Object.assign(state.homepageData, data);
  },
  setPages(state, pages) {
    state.pages = pages;
  },
  setCurrentPage(state, page) {
    state.currentPage = page;
  },
  setHomepage(state, homepage) {
    state.homepage = homepage;
  },
  setHomePageContent(state, content) {
    state.homePageContent = content;
  },
  setPageSlugs(state, pageSlugs) {
    state.pageSlugs = pageSlugs;
  },
  setPageNav(state, pageNav) {
    state.pageNav = pageNav;
  },
  addPage(state, page) {
    state.pages.push(page);
  },
  updatePage(state, page) {
    const index = state.pages.findIndex((p) => p.id === page.id);
    state.pages[index] = page;
  },
  removePage(state, pageId) {
    const index = state.pages.findIndex((page) => page.id === pageId);
    if (index !== -1) {
      state.pages.splice(index, 1);
    }
  },
  setUser(state, user) {
    state.user = user;
  },
  setAuthentication(state, status) {
    state.isAuthenticated = status;
  },
};
